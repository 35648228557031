import axios from 'axios'

const API_URL = window.location.origin;

const securedAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

const plainAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

securedAxiosInstance.interceptors.request.use(config => {
    const method = config.method.toUpperCase()
    if (method !== 'OPTIONS' && method !== 'GET') {
      config.headers = {
        ...config.headers,
        'X-CSRF-TOKEN': localStorage.csrf
      }
    }
    return config
});

securedAxiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.config && error.response.status === 401) {
      // If 401 by expired access cookie, we do a refresh request
      console.log("401 Error")
    } else {
      return Promise.reject(error)
    }
  });
  
export { securedAxiosInstance, plainAxiosInstance }
